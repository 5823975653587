<template>
  <div class='mt-4 mr-4 ml-4'>
    <system-execution-attempts :uuid='uuid'></system-execution-attempts>
  </div>
</template>

<script>
import SystemExecutionAttempts from '@/components/SFTP/SystemExecutions/SystemExecutionAttempts.vue';

export default {
  name: 'SystemExecutionsAttempts',
  components: { SystemExecutionAttempts },
  data: () => ({
    uuid: null,
  }),
  beforeMount() {
    this.uuid = this.$route.params.uuid;
  },
};

</script>
