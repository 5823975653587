<template>
  <div>
    <div v-if='loading'>
      <b-row>
        <b-col cols='12'>
          <b-spinner></b-spinner>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-card-header>
        <b-card-title>System Execution Attempts</b-card-title>
      </b-card-header>
        <b-card no-body>
          <div v-if='isNullOrEmpty(items)' class='mt-4 mb-2 ml-2 mr-2'>
            <b-alert show variant='danger'>No attempts found for this event</b-alert>
          </div>
          <div v-else>
          <b-tabs pills card vertical nav-wrapper-class='col-2'>
            <b-tab v-for='(item, index) in items'
                   :title="'Attempt ' + (parseInt(item.attempt, 10) + 1)"
                   :active='item.active'
                   :key='index'
                   @click.prevent='onTabChange(item.attempt)'>
              <b-card-text>
                <div v-if='loadingLogs'>
                  <b-row>
                    <b-col cols='12'>
                      <b-spinner></b-spinner>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <b-card title='Details' class='mb-4'>
                    <b-row class='mb-2'>
                      <b-col cols='3'>
                        <span>Filepath:</span>
                      </b-col>
                      <b-col cols='6'>
                        <span>{{ item.filepath }}</span>
                      </b-col>
                    </b-row>
                    <b-row class='mb-2'>
                      <b-col cols='3'>
                        <span>Type:</span>
                      </b-col>
                      <b-col cols='6'>
                        <span>{{ item.type }}</span>
                      </b-col>
                    </b-row>
                    <b-row class='mb-2'>
                      <b-col cols='3'>
                        <span>Storage Queue Id:</span>
                      </b-col>
                      <b-col cols='6'>
                        <span>{{ item.storage_queue_uuid }}</span>
                      </b-col>
                    </b-row>
                    <b-row class='mb-2'>
                      <b-col cols='3'>
                        <span>Created at:</span>
                      </b-col>
                      <b-col cols='6'>
                        <span>{{ item.created_at }}</span>
                      </b-col>
                    </b-row>
                    <b-row class='mb-2'>
                      <b-col cols='3'>
                        <span>Status:</span>
                      </b-col>
                      <b-col cols='6'>
                        <span>{{ item.status }}</span>
                      </b-col>
                    </b-row>
                    <b-row class='mb-2'>
                      <b-col cols='3'>
                        <span>Finished at:</span>
                      </b-col>
                      <b-col cols='6'>
                        <span>{{ item.finished_at }}</span>
                      </b-col>
                    </b-row>
                  </b-card>
                  <SystemExecutionAttemptLogs :logs='logs'></SystemExecutionAttemptLogs>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
          </div>
        </b-card>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { isNullOrEmpty } from '@/helpers';
import SystemExecutionAttemptLogs from './SystemExecutionAttemptLogs.vue';

export default {
  name: 'SystemExecutionAttempts',
  components: {
    SystemExecutionAttemptLogs,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  async beforeMount() {
    try {
      await this.getSystemExecutionAttempts();
      await this.getSystemExecutionAttemptLogs();
    } catch (e) {
      this.$noty.error(e.message);
    }
  },
  data() {
    return {
      loading: true,
      loadingLogs: true,
      logs: [],
      items: [],
      attempt: null,
    };
  },
  methods: {
    isNullOrEmpty,
    getSystemExecutionAttempts() {
      this.loading = true;
      this.items = [];
      return this.$store.dispatch('Sftp/SystemExecutions/getSystemExecutionAttempts',
        { uuid: this.uuid })
        .then(({ data }) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const attempt of data.attempts) {
            const item = {
              attempt: attempt.attempt_number,
              filepath: attempt.title,
              type: attempt.type,
              storage_queue_uuid: attempt.storage_queue_uuid,
              created_at: this.normalizeDate(attempt.created_at),
              status: attempt.status,
              finished_at: this.normalizeDate(attempt.finished_at),
            };
            this.items.push(item);
          }
          this.attempt = parseInt(data.last_attempt, 10);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSystemExecutionAttemptLogs() {
      this.loadingLogs = true;
      return this.$store.dispatch('Sftp/SystemExecutions/getSystemExecutionAttemptLogs',
        { uuid: this.uuid, attempt: this.attempt })
        .then(({ data }) => {
          this.logs = data;
        })
        .finally(() => {
          this.loadingLogs = false;
        });
    },
    async onTabChange(attempt) {
      if (this.attempt === attempt) return;
      this.attempt = attempt;
      await this.getSystemExecutionAttemptLogs();
    },
    normalizeDate(date) {
      if (isNullOrEmpty(date)) return '-';
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>
