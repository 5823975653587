<template>
  <b-card title='Logging'>
    <b-table
      class='system-executions-logs-table'
      :striped='true'
      :borderless='true'
      :hover='true'
      small
      :items='logs'
      :fields='fields'
      @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)">
      <template #cell(created_at)='row'>
        {{ normalizeDate(row.item.created_at) }}
      </template>
      <template #cell(log)='row'>
        <span style='white-space:pre-line'>{{ row.item.log }}</span>
      </template>
      <template #cell(failed)='row'>
        {{ row.item.failed ? 'Yes' : 'No' }}
      </template>
      <template #row-details='row' v-if='isAdmin'>
        <b-card sub-title='Metadata' style='max-width: 1500px'>
          <pre>{{ row.item.metadata | pretty }}</pre>
        </b-card>
        <b-card sub-title='Extra Attributes' style='max-width: 1500px'>
          <pre>{{ row.item.extra_attributes | pretty }}</pre>
        </b-card>
      </template>
    </b-table>
  </b-card>
</template>
<script>

import moment from 'moment';
import { isNullOrEmpty } from '@/helpers';

export default {
  name: 'SystemExecutionAttemptLogs',
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
  filters: {
    // eslint-disable-next-line consistent-return
    pretty(value) {
      if (isNullOrEmpty(value)) return 'No value to display.';
      try {
        return JSON.stringify(value, null, 2);
      } catch (e) {
        return 'Invalid JSON Input!!';
      }
    },
  },
  beforeMount() {
    this.isAdmin = this.$store.getters['User/isAdmin'];
  },
  data() {
    return {
      isAdmin: false,
      items: [],
      fields: [
        { key: 'created_at', label: 'Timestamp', thStyle: { width: '15%' } },
        { key: 'step_name', label: 'Step Name', thStyle: { width: '15%' } },
        { key: 'log', label: 'Log', thStyle: { width: '60%' } },
        { key: 'failed', label: 'Failed', thStyle: { width: '10' } },
      ],
    };
  },
  methods: {
    normalizeDate(date) {
      if (isNullOrEmpty(date)) return '-';
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>
